"use client";
import AboutUsSection from "../components/home/about-us/AboutUsSection";
import CallToActionSection from "../components/home/call-to-action/CallToActionSection";
import CollaboratedCompanySection from "../components/home/collaborated-company/CollaboratedCompanySection";
import EventSection from "@/components/home/event/EventSection";
import FacultySection from "../components/home/faculty/FacultySection";
import HeroSection from "../components/home/hero/HeroSection";
import KeyFeaturesSection from "../components/home/key-features/KeyFeaturesSection";
import LeadFormSection from "../components/home/lead-form/LeadFormSection";
import OurCoursesSection from "../components/home/our-courses/OurCoursesSection";
import SuccessStorySection from "../components/home/success-stories/SuccessStorySection";
import FreeCourses from "../components/home/free-courses/FreeCourses";
import Founder from "../components/home/founder/Founder";
import Internship from "@/components/home/internship/Internship";
import WhatsAppButton from "@/components/common/WhatsAppButton";
import DownloadOurApp from "@/components/common/DownloadOurApp";
import NewsChannel from "@/components/home/news/NewsChannel";
import CustomHead from "@/utils/CustomHead";
import { Slider } from "@/components/home/hero-slider/Slider";
import { CardReveal } from "@/components/home/cards/CardReveal";
import RequestCallback from "@/components/common/RequestCallback";
import { useRouter } from "next/navigation";
import { useState, useEffect } from "react";
import { prodServerUrl } from "@/app/utils/globals";
import { IoCloseCircleOutline } from "react-icons/io5";
import Image from "next/image";
import { Review } from "@/components/home/reviews/Review";

export default function page() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Set a timeout to delay the popup display
    const timeout = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Display popup after 1 second

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const [disable, setDisable] = useState(false);
  const [leadName, setLeadName] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");

  const router = useRouter();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!leadName && !phone && !selectedCourse) {
      alert("All Fields Are Necessary");
      return;
    }

    try {
      const res = await axios.post(`${prodServerUrl}/hero-forms`, {
        leadName,
        phone,
        selectedCourse,
      });

      if (res.status === 201) {
        alert("We have received your query. You will be contacted soon");
        router.push("/thank-you");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        {/* <Slider /> */}
        <HeroSection />
        {/* <KeyFeaturesSection /> */}
        <AboutUsSection />
        <EventSection />
        <CollaboratedCompanySection />
        <OurCoursesSection />
        <LeadFormSection />
        <FreeCourses />
        <FacultySection />
        {/* <CardReveal /> */}
        {/* <NewsChannel /> */}
        <CallToActionSection />
        {/* <Founder /> */}
        <SuccessStorySection />
        {/* <Review /> */}
        {/* <Internship/> */}
        <WhatsAppButton />
        {/* <DownloadOurApp /> */}
        {/* <RequestCallback /> */}
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <form
            onSubmit={handleSubmit}
            className="p-6 rounded backdrop-blur-[178px] border border-zinc-600"
          >
            <div className="flex flex-col justify-center items-center">
              <h5 className="uppercase tracking-widest text-blue-600 font-bold text-3xl">
                vidya<span className="text-yellow">s</span>tu
              </h5>
            </div>

            <p className="text-blue-600 my-3 text-xs text-center font-Poppins">
              Your Online Finishing School
            </p>
            <h5 className="font-Poppins text-md font-bold text-zinc-200 mb-3 text-center md:text-start">
              Admission is Open for {" "}
              <span className="text-lg text-blue-600">Data Science & Analytics</span> and {" "}
              <span className="text-lg text-yellow">Content Writing</span>.
            </h5>
            <div className="mb-1 font-Poppins">
              <input
                type="text"
                className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                onChange={(e) => setSelectedCourse(e.target.value)}
                placeholder="Which Course/(s) are you interested in?"
                required
              />
            </div>
            <div className="mb-1 font-Poppins">
              <input
                type="text"
                className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                onChange={(e) => setLeadName(e.target.value)}
                placeholder="Your Name"
                required
              />
            </div>
            <div className="mb-1 font-Poppins">
              <input
                type="number"
                className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Your Phone"
                required
              />
            </div>
            <div className="flex flex-row justify-between items-center gap-3 font-Poppins">
              <button
                className="text-sm font-Josefin font-medium px-4 py-2 mt-4 w-full border border-zinc-400 text-zinc-100 rounded-lg"
                onClick={() => setShowPopup(false)}
              >
                Close
              </button>
              <button
                className={`${
                  disable ? "bg-colorTextSecondary" : "bg-blue-900"
                } text-sm font-Josefin font-medium px-4 py-2 mt-4 w-full text-zinc-100 rounded-lg`}
                type="submit"
                disabled={disable ? true : false}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
