import React from "react";
import SuccessStories from "./SuccessStories";
import { Review } from "../reviews/Review";

const SuccessStorySection = () => {
  return (
    <div className="container mx-auto py-8 m-auto md:py-14 md:px-16 lg:px-56">
      <div className="px-14 md:px-0">
        <h3 className="text-2xl font-semibold text-colorHeading uppercase">
          What they're saying about
          <span className="text-red-700"> Vidyastu</span>
        </h3>
        <div className="underline"></div>
      </div>
      {/* <SuccessStories /> */}
      <Review />
    </div>
  );
};

export default SuccessStorySection;
